import css from './style.module.scss';
import A from '../A';
import Image from 'next/image';
import { useState } from 'react';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { gql } from '../../util/fetchGql';
import tidyUri from '../../util/tidyUri';

export const PRODUCT_CARD_FRAGMENT = gql`
	fragment ProductCard on products_Product {
		key: id
		title
		uri
		price: defaultPriceAsCurrency
		brands (limit: 1) {
			title
		}
		variants {
			... on products_Variant {
                key: id
                title
				colour
				image {
					... on uploads_Asset {
						contain
                        url @transform(
                            width: 960
                            height: 975
	                        mode: "crop"
                        )
						containedUrl: url @transform(
                            width: 960
                            height: 975
                            mode: "fit"
                        )
                    }
				}
			}
		}
	}
`;

const Variant = ({ fill, index, active, setIndex }) => {
	const onHover = () => setIndex(index);

	return (
		<span style={{'--fill':fill}} onMouseEnter={onHover}>
			{active === index && <motion.span layoutId="cardActiveVariant" />}
		</span>
	);
};

export default function ProductCard ({
	title, uri, price, brands, variants = [],
	animation = {},
}) {
	const [activeVariant, setActiveVariant] = useState(0);

	const url = uri + (variants.length > 0 ? `#variant_${variants[activeVariant].key}` : '');

	return (
		<A href={tidyUri(url)} className={css.productCard} draggable={false} animation={animation}>
			<span className={css.imgs}>
				<motion.span
					animate={{ x: (-100 * activeVariant) + '%' }}
					transition={{ type: 'tween' }}
				>
					{variants.map(variant => variant.image.length && (
						<Image unoptimized
							key={variant.key}
							src={variant.image[0][variant.image[0].contain ? 'containedUrl' : 'url']}
							width={320}
							height={325}
						/>
					))}
				</motion.span>
			</span>
			<span className={css.copy}>
				<span className={css.top}>
					<span className={css.brand}>{brands?.[0]?.title}</span>
				</span>
				<strong className={css.name}>{title}</strong>
				<span className={css.variants}>
					<AnimateSharedLayout>
						{variants.map((variant, i) => (
							<Variant
								key={variant.key}
								fill={variant.colour || 'rgba(0,0,0,0.1)'}
								index={i}
								active={activeVariant}
								setIndex={setActiveVariant}
							/>
						))}
					</AnimateSharedLayout>
					<span className={css.price}>{price}</span>
				</span>
			</span>
		</A>
	);
}
