import css from './style.module.scss';
import Wrap from '../Wrap';
import Button, { TYPED_LINK_FRAGMENT } from '../Button';
import Image from 'next/image';
import { gql } from '../../util/fetchGql';
import cls from '../../util/cls';

export const BANNER_FRAGMENT = gql`
	fragment Banner on homeHero_image_BlockType {
		subheading
		heading
		description
		cta {
			...TypedLink
		}
		accentColour
		image {
			url @transform(
				width: 2400
				height: 1600
			)
		}
	}
	${TYPED_LINK_FRAGMENT}
`;

export default function Banner ({
	subheading,
	heading,
	description,
	cta,
	accentColour,
	image: [{ url }],
}) {
	return (
		<Wrap
			wide
			className={css.banner}
		>
			<div className={css.img}>
				<div>
					<Image unoptimized
						src={url}
						width={1720}
						height={918}
						sizes="100vw"
						priority
					/>
				</div>
			</div>
			<div className={cls(css.content, css[accentColour])}>
				<h4>{subheading}</h4>
				<h1>{heading}</h1>
				<p>{description}</p>
				<Button
					{...cta}
					wide
					white={accentColour === 'black' || accentColour === 'blue'}
					blue={accentColour === 'white'}
				/>
			</div>
		</Wrap>
	);
}
