import css from './style.module.scss';
import Wrap from '../Wrap';
import { useEffect, useRef, useState } from 'react';
import cls from '../../util/cls';

export default function Deck ({
	inline = false,
	scroll = false,
	pullLeft = false,
	children,
}) {
	const scroller = useRef()
		, [disableSnap, setDisableSnap] = useState(false)
		, [isDragging, setIsDragging] = useState(false);

	useEffect(() => {
		if (!scroller.current || window.matchMedia('(max-width: 62em)').matches)
			return;

		const el = scroller.current;
		let isDragging = false,
			wasDragged = false,
			target = null,
			startX = 0,
			startS = 0;

		const onMouseMove = e => {
			const change = e.clientX - startX;
			el.scrollLeft = startS - change;
		};

		const onDragStart = e => {
			e.preventDefault();
			wasDragged = true;
			return false;
		};

		const onMouseUp = () => {
			isDragging = false;
			setIsDragging(false);
			document.body.style.userSelect = '';
			document.removeEventListener('mouseup', onMouseUp);
			document.removeEventListener('mousemove', onMouseMove);
			document.body.removeEventListener('dragstart', onDragStart);

			if (wasDragged) wasDragged = false;
			else target?.click();
		};

		const onMouseDown = e => {
			document.body.style.userSelect = 'none';

			setIsDragging(true);
			setDisableSnap(true);

			target = e.target.closest('a');
			isDragging = true;
			startX = e.clientX;
			startS = el.scrollLeft;

			document.addEventListener('mousemove', onMouseMove);
			document.addEventListener('mouseup', onMouseUp);
			document.body.addEventListener('dragstart', onDragStart);
		};

		const onScroll = () => {
			if (!isDragging)
				setDisableSnap(false);
		};

		el.addEventListener('mousedown', onMouseDown);
		el.addEventListener('scroll', onScroll);

		return () => {
			el.removeEventListener('mousedown', onMouseDown);
			el.removeEventListener('scroll', onScroll);
		};
	}, [scroller]);

	return (
		<Wrap
			grid={!scroll}
			className={cls(css.deck, {
				[css.inline]: inline,
				[css.pullLeft]: pullLeft,
			})}
		>
			{scroll ? (
				<>
					<span className={css.edges} />
					<div className={cls(css.scroll, {
						[css.noSnap]: disableSnap,
						[css.dragging]: isDragging,
					})} ref={scroller}>
						{children}
					</div>
				</>
			) : children}
		</Wrap>
	);
}
