import css from './style.module.scss';
import cls from '../../util/cls';

export default function Pill ({
	blue = false,
	white = false,
	grey = false,
	className,
	children,
}) {
	return (
		<span className={cls(css.pill, className, {
			[css.blue]: blue,
			[css.white]: white,
			[css.grey]: grey,
		})}>{children}</span>
	);
}
