import Banner, { BANNER_FRAGMENT } from '../components/Banner';
import fetchGql, { gql } from '../util/fetchGql';
import Seo, { SEO_FRAGMENT } from '../components/Seo';
import HomeContent, { HOME_CONTENT_FRAGMENT } from '../components/HomeContent';

export default function Home ({ seo, homeHero: [banner], homeContent, posts }) {
	return (
	    <>
		    <Seo seo={seo} />
		    <Banner {...banner} />
		    <HomeContent blocks={homeContent} posts={posts} />
	    </>
	);
}

export async function getStaticProps ({ params, ...ctx }) {
	const data = await fetchGql(gql`
        query GetHome {
	        entry (slug: "home") {
		        ... on home_home_Entry {
			        seo { ...Seo }
			        homeHero {
				        ...Banner
			        }
			        homeContent {
				        ...HomeContent
			        }
		        }
	        }
			posts:entries (
				section: "post"
				limit: 3
				orderBy: "postDate desc"
			) {
				...NewsCard
			}
        }
        ${SEO_FRAGMENT}
        ${BANNER_FRAGMENT}
        ${HOME_CONTENT_FRAGMENT}
	`, null, ctx);

	return {
		props: {
			...data.entry,
			posts:data.posts
		},
		
	};
}
