import css from './style.module.scss';
import A from '../A';
import Image from 'next/image';
import { gql } from '../../util/fetchGql';
import tidyUri from '../../util/tidyUri';

export const NEWS_CARD_FRAGMENT = gql`
	fragment NewsCard on post_default_Entry {
		key: id
		uri
		title
		postDate @formatDateTime(format: "jS F Y")
		image {
			url @transform(
				width: 1320
				height: 600
			)
		}
		newsCategory {
			title
		}
	}
`;

export default function NewsCard ({ uri, title, postDate, image: [img], newsCategory: [cat] }) {
	return (
		<A href={tidyUri(uri)} className={css.newsCard}>
			{img && (
				<Image unoptimized
					src={img.url}
					width={440}
					height={200}
				/>
			)}
			<span className={css.copy}>
				<span className={css.top}>
					{cat && <span>{cat.title}</span>}
					<time>{postDate}</time>
				</span>
				<strong>{title}</strong>
			</span>
		</A>
	);
}
