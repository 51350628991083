import css from './style.module.scss';
import cls from '../../util/cls';
import Image from 'next/image';
import Button, { TYPED_LINK_FRAGMENT } from '../Button';
import Wrap from '../Wrap';
import Pill from '../Pill';
import { gql } from '../../util/fetchGql';

export const WIDGET_FRAGMENT = gql`
	fragment Widget on widget_BlockType {
		key: id
		columns
		rows
		imagePosition
		image {
			url @transform(
				width: 1400
				height: 1400
			)
		}
		textAlign
		backgroundFill
		pill
		subheading
		heading
		description
		cta {
			...TypedLink
		}
	}
	${TYPED_LINK_FRAGMENT}
`;

export function WidgetWrap ({ widget }) {
	return (
		<Wrap wide grid className={css.wrap}>
			{widget.map(widget => <Widget {...widget} />)}
		</Wrap>
	);
}

/**
 * @param {Number} columns
 * @param {Number} rows
 * @param {'left'|'fill'} imagePosition
 * @param {'left'|'center'} textAlign
 * @param {'none'|'white'|'blue'|'black'} backgroundFill
 * @param {String?} pill
 * @param cta
 * @param children
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Widget ({
	columns = 1,
	rows = 1,
	imagePosition = 'left',
	textAlign = 'left',
	backgroundFill = 'none',
	pill = null,
	cta = null,
	subheading,
	heading,
	description,
	image: [img],
	...props
}) {
	const fill = imagePosition === 'fill';

	const El = cta?.href ? 'a' : 'div';

	return (
		<El
			href={cta?.href}
			className={cls(css.widget, {
				[css.textCenter]: textAlign === 'center',
				[css.none]: backgroundFill === 'none',
				[css.white]: backgroundFill === 'white',
				[css.black]: backgroundFill === 'black' || !backgroundFill,
				[css.blue]: backgroundFill === 'blue',
				[css.imageFill]: fill,
			})}
			style={{
				'--widget-columns': columns,
				'--widget-rows': rows,
			}}
			{...props}
		>
			{img && (
				<div className={css.img}>
					<Image unoptimized
						src={img.url}
						width={fill ? 840 : 380}
						height={fill ? 840 : 320}
					/>
				</div>
			)}
			<div className={css.text}>
				<div className={css.copy}>
					{pill && (
						<Pill
							className={css.pill}
						>
							{pill}
						</Pill>
					)}
					{subheading && <p>{subheading}</p>}
					{heading && <h2>{heading}</h2>}
					{description && <p>{description}</p>}
				</div>
				{cta && (
					<Button
						className={css.cta}
						wide
						white={backgroundFill === 'black' || backgroundFill === 'blue' || !backgroundFill || fill}
						{...cta}
					/>
				)}
			</div>
		</El>
	);
}
