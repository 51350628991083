import css from './style.module.scss';
import Wrap from '../Wrap';

export default function Heading ({ children }) {
	return (
		<Wrap El="header" className={css.heading}>
			{children}
		</Wrap>
	);
}
