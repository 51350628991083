import { Fragment } from 'react';
import { WIDGET_FRAGMENT, WidgetWrap } from './Widget';
import { gql } from '../util/fetchGql';
import ProductCard, { PRODUCT_CARD_FRAGMENT } from './ProductCard';
import Heading from './Heading';
import Deck from './Deck';
import NewsCard, { NEWS_CARD_FRAGMENT } from './NewsCard';
import A from './A';
import Wrap from './Wrap';

export const HOME_CONTENT_FRAGMENT = gql`
	fragment HomeContent on MatrixBlockInterface {
		key: id
		typeHandle
		... on homeContent_widgets_BlockType {
			widget { ...Widget }
		}
		... on homeContent_featuredProducts_BlockType {
			heading
			products (isHidden: false) {
				...ProductCard
			}
		}
		... on homeContent_latestNews_BlockType {
			posts (
				limit: 3
				orderBy: "postDate desc"
			) {
				...NewsCard
			}
		}
	}
	${WIDGET_FRAGMENT}
	${PRODUCT_CARD_FRAGMENT}
	${NEWS_CARD_FRAGMENT}
`;

export default function HomeContent ({ blocks, posts }) {
	return (blocks ?? []).map(block => {
		switch (block.typeHandle) {
			case 'widgets':
				return <WidgetWrap {...block} />;
			case 'featuredProducts':
				return (
					<Fragment key={block.key}>
						<Heading>
							<h2>{block.heading ?? 'Featured Products'}</h2>
						</Heading>

						<Deck scroll>
							{block.products.map(product => <ProductCard {...product} />)}
						</Deck>
					</Fragment>
				);
			case 'latestNews':
				return (
					<Fragment key={block.key}>
						<Heading>
							<h2>Latest News</h2>
							<A href="/news">View all news</A>
						</Heading>

						<Wrap grid>
							{[...block.posts, ...posts].slice(0,3).map(post => <NewsCard {...post} />)}
						</Wrap>
					</Fragment>
				);
			default:
				return block.typeHandle;
		}
	});
}
